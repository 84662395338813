@import "src/styles/colors.scss";
.container {
  grid-column: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 20px;
  margin: 30px 0;
}

.backArrow {
  composes: cp from '../../styles/common.module.scss';
}

.title {
  composes: fs-20 from '../../styles/common.module.scss';
  composes: lh-20 from '../../styles/common.module.scss';
  composes: colorGray from '../../styles/common.module.scss';
  composes: fw-N from '../../styles/common.module.scss';
  composes: tt-U from '../../styles/common.module.scss';
}

@import 'PageBackTitle.medium';
@import 'PageBackTitle.mobile';
