@import "src/styles/colors.scss";
.container {
  position: relative;
  border: 1px solid $gray-color;
  border-radius: 10px;
  display: flex;
  height: 136px;
}

.opacity05 {
  opacity: 0.5;
}

.photoIcon {
  margin: auto;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.labelImgPreview {
  width: 100%;
  height: 100%;
  &:hover {
    .imgPreview {
      object-fit: contain;
    }
  }
}

.imgPreview {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.cancelIconWrapper {
  composes: cp from '../../styles/common.module.scss';
  composes: ta_c from '../../styles/common.module.scss';

  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  background-color: $yellow-color;
  border-radius: 50%;
}

.cancelIcon {
  margin: auto;
}

.photoInput {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
}

@import 'Photo.mobile';
