@import "src/styles/colors.scss";
.infoBlock {
  composes: fs-16 from '../../styles/common.module.scss';
  grid-area: info;
  margin-left: 20px;
  display: grid;
  grid-row-gap: 20px;
}

@import "AdvInfoBlock.mobile";
