@import "src/styles/colors.scss";
.icon {
  position: relative;
  display: inline-block;
  height: 25px;
  width: 29px;
  vertical-align: bottom;
  margin-right: 10px;
}

.menuLink {
  composes: menuLinkTitle from '../../styles/common.module.scss';
}

.heartIcon {
  background: url('../../images/icons/heart.png') no-repeat;
}

.messageIcon {
  background: url('../../images/icons/message.png') no-repeat;
}

.counterBlock {
  height: 20px;
  width: 20px;
  position: absolute;
  right: -8px;
  top: -5px;
}

@import 'Notification.mobile';
