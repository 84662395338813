@import '../../styles/respond_to';

@include respond-to(mobile) {
  .container {
    padding: 0;
    background: transparent;
    grid-template-areas:
      'title'
      'image'
      'content'
      'divider'
      'actions';
  }

  .image {
    width: 300px;
  }

  .content,
  .location {
    width: 300px;
    font-size: 12px;
  }

  .title {
    width: 300px;
    text-align: center;
    margin-bottom: 10px;
  }
  .actions {
    width: 300px;
    button:nth-child(2) {
      margin-bottom: 20px;
    }
  }

  .label {
    font-size: 14px;
    line-height: 14px;
  }

  .value {
    font-size: 12px;
    line-height: 12px;
  }

  .priceBlock {
    padding: 17px 23px;
    font-size: 12px;
    line-height: 12px;
  }

  .goodsList {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }

  .similarGoods {
    margin-top: 20px;
  }

  .similarGoodsTitle {
    font-size: 14px;
    line-height: 17px;
  }
}
