@import "src/styles/colors.scss";
@import '../../styles/respond_to';

.searchBar {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: end;
  padding: 20px 0;
}

@include respond-to(tablet) {
  .searchBar {
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, 25px);
  }
}

.searchBarFormItem {
  margin-top: 0;
}

.searchBarInput {
  composes: colorGray from '../../styles/common.module.scss';

  font-size: 14px;

  &::placeholder {
    color: $gray-color;
  }
}

.searchBarSelect {
  composes: colorGray from '../../styles/common.module.scss';

  > div > input {
    font-size: 14px !important;

    &::placeholder {
      color: $gray-color !important;
    }
  }
}

.newAdvertBtn {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translateY(200%);
}

.bannerTop {
  grid-column: 1 / span 3;
  display: grid;
  position: relative;

  img {
    width: 100%;
  }
}

.categories [role='list'] {
  min-width: 200px;
}

.advs {
  composes: dg from '../../styles/common.module.scss';
  composes: ma-t20 from '../../styles/common.module.scss';
  grid-template-columns: repeat(4, 220px);
  grid-gap: 30px 73px;
}

.select {
  composes: lh-20 from '../../styles/common.module.scss';
  composes: tt-U from '../../styles/common.module.scss';

  font-family: 'Bebas Neue', sans-serif;
  color: $gray-color;

  & > div > span:not([role='option']) {
    font-size: 18px;
  }

  > div > input:only-child {
    min-width: 150px;
  }

  > div > input {
    font-size: 18px !important;

    &::placeholder {
      color: $gray-color !important;
      opacity: 1 !important;
      text-transform: uppercase;
    }
  }
}

.arrowDown {
  transition: transform 0.3s;
}

@import 'HomePage.large';
@import 'HomePage.medium';
@import 'HomePage.mobile';
