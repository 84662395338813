@import "src/styles/colors.scss";
@import '~normalize.css';
@import '~react-notifications/lib/notifications.css';

body {
  background-color: rgba(221, 184, 6, 0.05);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray-color;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: $light-gray-color;
  }
}
