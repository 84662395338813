@import "src/styles/colors.scss";
.adv {
  composes: bg-colorWhite from '../../styles/common.module.scss';

  border-radius: 10px;
  padding: 10px;
  position: relative;

  &:hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  }
}

.imageWrapper {
  position: relative;
  height: 270px;
  text-align: center;
}

.advPrice {
  composes: colorGray from '../../styles/common.module.scss';
  composes: fs-14 from '../../styles/common.module.scss';
  composes: lh-16 from '../../styles/common.module.scss';
  composes: ta_c from '../../styles/common.module.scss';

  background-color: $yellow-color;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 5px 5px;
  position: absolute;
  top: -10px;
  left: 0;
  padding: 10px 10px 5px;
}

.advImage {
  max-width: 200px;
  max-height: 266px;
  min-height: 50px;
  border-radius: 15px;
  composes: cp from '../../styles/common.module.scss';
}

.advBottomWrapper {
  position: relative;
  height: 45px;
  display: flex;
}

.advTitle {
  color: #3e97ff;
  font-size: 16px;
  line-height: 18px;
  width: 170px;
  align-self: flex-end;
  composes: cp from '../../styles/common.module.scss';
}

.icon {
  composes: cp from '../../styles/common.module.scss';
  width: 25px;
  height: 25px;
  position: absolute;
  top: -10px;
  right: 0;
  margin: 10px 0 10px 10px;
}

.favoriteOn {
  composes: icon;
  background: url('../../images/icons/heart-red.png') no-repeat;
}

.favoriteOff {
  composes: icon;
  background: url('../../images/icons/heart-red-b.png') no-repeat;
}

.order {
  composes: icon;
  background-size: contain;
  background-image: url('../../images/icons/message-blue.png');
  bottom: 0;
  top: unset;
  margin: 10px 0 0 10px;
}

@import 'Adv.mobile';
