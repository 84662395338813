@import "src/styles/colors.scss";
.container {
  composes: ff-GP from '../../styles/common.module.scss';
  position: relative;
  width: 300px;
  height: 90px;
}

.circle {
  composes: fs-48 from '../../styles/common.module.scss';
  composes: lh-100 from '../../styles/common.module.scss';
  composes: ta_c from '../../styles/common.module.scss';
  composes: fw-N from '../../styles/common.module.scss';
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: $white-color;
  position: absolute;
  z-index: 1;
}

.rectangle {
  width: 200px;
  height: 70px;
  margin: 10px 45px 10px 55px;
  position: relative;
  display: flex;
  align-items: center;
}

.square {
  position: absolute;
  top: 9px;
  right: -25px;
  width: 52px;
  height: 52px;
  border-radius: 5px;
  transform: rotateZ(45deg);
}

.text {
  composes: fs-14 from '../../styles/common.module.scss';
  composes: lh-16 from '../../styles/common.module.scss';
  color: $white-color;
  z-index: 1;
  padding: 10px 10px 10px 45px;
}
