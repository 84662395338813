@import "../../styles/respond_to";

@include respond-to(large) {
  .ruleBlock {
    grid-template-columns: 1fr;

    & > div {
      &:nth-child(2n) {
        margin-top: 0;
        top: 0;
        left: 160px;
      }
    }
  }

  .imageSize {
    width: 460px;
    height: 193px;
  }
}
