@import "src/styles/colors.scss";
.container {
  composes: dg from '../../styles/common.module.scss';
  composes: gtc_5fr_1100px_5fr from '../../styles/common.module.scss';
}

.bannerBottom {
  composes: dg from '../../styles/common.module.scss';
  grid-column: 1/span 3;

  img {
    width: 100%;
    height: 100%;
  }
}
