@import '../../styles/respond_to';

@include respond-to(mobile) {
  .advRowBlock {
    grid-template-columns: 140px 1fr;
    grid-template-areas:
      'image info'
      'image del';
  }
}
