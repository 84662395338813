@import '../../styles/respond_to';

@include respond-to(large) {
  .container {
    grid-template-areas:
      'image title'
      'image content'
      'divider divider'
      'actions actions';
  }

  .image {
    width: 360px;
  }

  .goodsList {
    grid-column-gap: 20px;
  }
}
