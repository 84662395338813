@import "src/styles/colors.scss";
.wrapper {
  position: relative;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
}

// https://projects.lukehaas.me/css-loaders/
// main color is - HEX #f68667 or RGB 246,134,103
.loader {
  top: 50%;
  left: 50%;
  position: absolute;
  font-size: 14px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  text-indent: -9999em;
  animation: load5 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #f68667, 1.8em -1.8em 0 0em rgba(246, 134, 103, 0.2),
      2.5em 0em 0 0em rgba(246, 134, 103, 0.2), 1.75em 1.75em 0 0em rgba(246, 134, 103, 0.2),
      0em 2.5em 0 0em rgba(246, 134, 103, 0.2), -1.8em 1.8em 0 0em rgba(246, 134, 103, 0.2),
      -2.6em 0em 0 0em rgba(246, 134, 103, 0.5), -1.8em -1.8em 0 0em rgba(246, 134, 103, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(246, 134, 103, 0.7), 1.8em -1.8em 0 0em #f68667,
      2.5em 0em 0 0em rgba(246, 134, 103, 0.2), 1.75em 1.75em 0 0em rgba(246, 134, 103, 0.2),
      0em 2.5em 0 0em rgba(246, 134, 103, 0.2), -1.8em 1.8em 0 0em rgba(246, 134, 103, 0.2),
      -2.6em 0em 0 0em rgba(246, 134, 103, 0.2), -1.8em -1.8em 0 0em rgba(246, 134, 103, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(246, 134, 103, 0.5),
      1.8em -1.8em 0 0em rgba(246, 134, 103, 0.7), 2.5em 0em 0 0em #f68667,
      1.75em 1.75em 0 0em rgba(246, 134, 103, 0.2), 0em 2.5em 0 0em rgba(246, 134, 103, 0.2),
      -1.8em 1.8em 0 0em rgba(246, 134, 103, 0.2), -2.6em 0em 0 0em rgba(246, 134, 103, 0.2),
      -1.8em -1.8em 0 0em rgba(246, 134, 103, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(246, 134, 103, 0.2),
      1.8em -1.8em 0 0em rgba(246, 134, 103, 0.5), 2.5em 0em 0 0em rgba(246, 134, 103, 0.7),
      1.75em 1.75em 0 0em #f68667, 0em 2.5em 0 0em rgba(246, 134, 103, 0.2),
      -1.8em 1.8em 0 0em rgba(246, 134, 103, 0.2), -2.6em 0em 0 0em rgba(246, 134, 103, 0.2),
      -1.8em -1.8em 0 0em rgba(246, 134, 103, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(246, 134, 103, 0.2),
      1.8em -1.8em 0 0em rgba(246, 134, 103, 0.2), 2.5em 0em 0 0em rgba(246, 134, 103, 0.5),
      1.75em 1.75em 0 0em rgba(246, 134, 103, 0.7), 0em 2.5em 0 0em #f68667,
      -1.8em 1.8em 0 0em rgba(246, 134, 103, 0.2), -2.6em 0em 0 0em rgba(246, 134, 103, 0.2),
      -1.8em -1.8em 0 0em rgba(246, 134, 103, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(246, 134, 103, 0.2),
      1.8em -1.8em 0 0em rgba(246, 134, 103, 0.2), 2.5em 0em 0 0em rgba(246, 134, 103, 0.2),
      1.75em 1.75em 0 0em rgba(246, 134, 103, 0.5), 0em 2.5em 0 0em rgba(246, 134, 103, 0.7),
      -1.8em 1.8em 0 0em #f68667, -2.6em 0em 0 0em rgba(246, 134, 103, 0.2),
      -1.8em -1.8em 0 0em rgba(246, 134, 103, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(246, 134, 103, 0.2),
      1.8em -1.8em 0 0em rgba(246, 134, 103, 0.2), 2.5em 0em 0 0em rgba(246, 134, 103, 0.2),
      1.75em 1.75em 0 0em rgba(246, 134, 103, 0.2), 0em 2.5em 0 0em rgba(246, 134, 103, 0.5),
      -1.8em 1.8em 0 0em rgba(246, 134, 103, 0.7), -2.6em 0em 0 0em #f68667,
      -1.8em -1.8em 0 0em rgba(246, 134, 103, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(246, 134, 103, 0.2),
      1.8em -1.8em 0 0em rgba(246, 134, 103, 0.2), 2.5em 0em 0 0em rgba(246, 134, 103, 0.2),
      1.75em 1.75em 0 0em rgba(246, 134, 103, 0.2), 0em 2.5em 0 0em rgba(246, 134, 103, 0.2),
      -1.8em 1.8em 0 0em rgba(246, 134, 103, 0.5), -2.6em 0em 0 0em rgba(246, 134, 103, 0.7),
      -1.8em -1.8em 0 0em #f68667;
  }
}
