@import "src/styles/colors.scss";
.pageButton {
  composes: cp from '../../styles/common.module.scss';
  composes: fs-18 from '../../styles/common.module.scss';
  composes: lh-18 from '../../styles/common.module.scss';
  composes: colorBlue from '../../styles/common.module.scss';
  border: 1px solid $blue-color;
  background-color: transparent;
  display: inline-block;
  border-radius: 5px;
  padding: 6px 10px;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  &:hover:not(.disabled) {
    color: $white-color;
    background-color: $blue-color;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.currentPage {
  color: $white-color;
  background-color: $blue-color;
}

.pagination {
  composes: ta_c from '../../styles/common.module.scss';
  composes: ma-t10 from '../../styles/common.module.scss';
}

.seeMore {
  composes: cp from '../../styles/common.module.scss';
  composes: colorBlue from '../../styles/common.module.scss';
  composes: tt-U from '../../styles/common.module.scss';
  composes: ma-t30 from '../../styles/common.module.scss';
  composes: ta_c from '../../styles/common.module.scss';
}
