@import "src/styles/colors.scss";
.container {
  padding-bottom: 50px;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.formItem {
  composes: fs-18 from '../../styles/common.module.scss';
  composes: lh-22 from '../../styles/common.module.scss';
}

.rowWith2Cols {
  composes: rowWith2Cols from '../../styles/common.module.scss';
}

.rowWith3Cols {
  composes: rowWith3Cols from '../../styles/common.module.scss';
}

.rowWith4Cols {
  composes: rowWith4Cols from '../../styles/common.module.scss';
}

.submitBlock {
  composes: ma-t20 from '../../styles/common.module.scss';
  composes: dg from '../../styles/common.module.scss';
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 60px;
  align-items: center;
}

.tal {
  text-align: left;
}

.photos {
  composes: ma-t20 from '../../styles/common.module.scss';
  composes: dg from '../../styles/common.module.scss';
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.goToRuleLink {
  composes: colorBlue from '../../styles/common.module.scss';
  composes: ff-GP from '../../styles/common.module.scss';
  composes: fs-11 from '../../styles/common.module.scss';
  composes: tdl-U from '../../styles/common.module.scss';
  composes: tt-U from '../../styles/common.module.scss';
  composes: cp from '../../styles/common.module.scss';
  letter-spacing: -0.5px;
}

.h3 {
  composes: ta_c from '../../styles/common.module.scss';
  composes: colorBlue from '../../styles/common.module.scss';
  composes: fs-20 from '../../styles/common.module.scss';
  composes: lh-22 from '../../styles/common.module.scss';
  composes: ff-GP from '../../styles/common.module.scss';
}

.ruleBlock {
  composes: dg from '../../styles/common.module.scss';
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  padding-bottom: 15px;

  & > div {
    &:nth-child(2n) {
      margin-top: 30px;
      top: 30px;
    }
  }
}

.imageSize {
  composes: ma-t30 from '../../styles/common.module.scss';

  width: 620px;
  height: 260px;
  border-radius: 15px;
}

.imageYellow {
  composes: imageSize;
  background: url('../../images/banners/b-top-620.png') no-repeat;
  background-size: 100%;
}

.imageRed {
  composes: imageSize;
  background: url('../../images/banners/b-bottom-620.png') no-repeat;
  background-size: 100%;
}

.selectManufacturer {
  & div[role='button'] {
    font-size: 18px;
    margin: 5px 0;
    color: #f68667;
  }
}

@import 'NewAdvertPage.large';
@import 'NewAdvertPage.medium';
@import 'NewAdvertPage.mobile';
