@import '../../styles/respond_to';

@include respond-to(mobile) {
  .container {
    grid-template-columns: auto;
    height: 80vh;
  }

  .container.showMessages {
    grid-template-rows: 30px auto;
  }

  .conversationSwitcher {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid #ccc;
  }

  .showDialogs .right,
  .showMessages .left {
    display: none !important;
  }

  /* Conversations */
  .unreadCount {
    width: 20px !important;
    height: 20px !important;
  }

  /* Messages */
  .top {
    height: 86px;
    box-sizing: border-box;
  }

  .msgProductName {
    font-size: 14px;
    text-overflow: ellipsis;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
  }

  .productName {
    font-size: 10px;
  }

  .selected {
    background-color: white;
  }
}
