@import '../../styles/respond_to';

@include respond-to(medium) {
  .advs {
    grid-template-columns: repeat(3, 220px);
    grid-gap: 30px 60px;
  }
}

@include respond-to(tablet) {
  .advs {
    grid-template-columns: repeat(2, 45%);
    grid-gap: 60px 10%;
  }

  .newAdvertBtnMobile {
    grid-column: 2;
    margin-top: 10px;
    justify-self: center;
  }
}
