@import "src/styles/colors.scss";
.filters {
  composes: colorGray from '../../styles/common.module.scss';
  composes: tt-U from '../../styles/common.module.scss';

  display: flex;
  justify-content: space-between;
  font-size: 24px;
  line-height: 24px;
}

@import 'FiltersBlock.mobile';
