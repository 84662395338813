@import "src/styles/colors.scss";
.advRowBlock {
  composes: colorGray from '../../styles/common.module.scss';
  composes: ff-GP from '../../styles/common.module.scss';
  composes: dg from '../../styles/common.module.scss';
  grid-template-columns: 220px 1fr 1fr;
  grid-template-areas:
    "image info del";
  align-items: center;
}

@import "AdvRowBlock.mobile";
