@import "src/styles/colors.scss";
.mailUs {
  composes: fw-N from '../../styles/common.module.scss';
  color: $red-color;
}

.social {
  grid-area: social;
  text-align: right;
}

.icon {
  height: 27px;
  width: 27px;
  display: inline-block;
  margin-top: 10px;
}
.facebook {
  composes: icon;
  background: url('../../images/icons/fb-white.png') no-repeat;
  padding-right: 15px;
}

.instagram {
  composes: icon;
  background: url('../../images/icons/instagram-white.png') no-repeat;
}

.inSocial {
  composes: tt-U from '../../styles/common.module.scss';
  color: white;
}

.leftSocial {
  text-align: left;
}

@import 'SocialBlock.mobile';
