@import "src/styles/colors.scss";
@import '../../styles/respond_to';

.container {
  background: #ffffff;
  padding: 20px;
  display: grid;
  grid-template-areas:
    'image title'
    'image content'
    'image divider'
    'image actions';
  grid-column-gap: 20px;
  grid-template-columns: max-content;
}

.title {
  grid-area: title;
}

.image {
  grid-area: image;
  width: 520px;

  img {
    width: 100%;
  }
}

.content {
  grid-area: content;
  position: relative;
  composes: colorGray from '../../styles/common.module.scss';
  composes: ff-GP from '../../styles/common.module.scss';
}

@include respond-to(tablet) {
  .content {
    grid-row-start: unset;
  }
}

.actions {
  grid-area: actions;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  button:nth-child(2) {
    margin-bottom: 30px;
  }
}

.label {
  composes: fs-18 from '../../styles/common.module.scss';
  composes: lh-18 from '../../styles/common.module.scss';
  composes: fw-B from '../../styles/common.module.scss';
  composes: tt-U from '../../styles/common.module.scss';
  composes: ff-BN from '../../styles/common.module.scss';
}

.value {
  composes: fs-16 from '../../styles/common.module.scss';
  composes: lh-16 from '../../styles/common.module.scss';
  composes: tt-U from '../../styles/common.module.scss';
}

.divider {
  border-bottom: 1px solid $gray-color;
  margin: 20px 0;
  height: 1px;
  width: 100%;
}

.lastDivider {
  composes: divider;
  grid-area: divider;
}

.params {
  display: grid;
  grid-template-columns: 160px 1fr;
  grid-row-gap: 10px;
  composes: ff-BN from '../../styles/common.module.scss';
}

.blueLabel {
  composes: colorBlue from '../../styles/common.module.scss';
  composes: ff-GP from '../../styles/common.module.scss';
  composes: fs-10 from '../../styles/common.module.scss';
}

.sellerInfo {
  margin-top: 31px;
}

.relative {
  position: relative;
}

.priceBlock {
  composes: fs-18 from '../../styles/common.module.scss';
  composes: lh-20 from '../../styles/common.module.scss';
  composes: ta_c from '../../styles/common.module.scss';
  composes: ff-BN from '../../styles/common.module.scss';
  composes: colorGray from '../../styles/common.module.scss';

  background-color: $yellow-color;
  border-radius: 0 0 5px 5px;
  position: absolute;
  right: 0;
  padding: 30px 31px;
}

.location {
  composes: fs-16 from '../../styles/common.module.scss';
  composes: dg from '../../styles/common.module.scss';
  grid-template-columns: 25px 1fr 25px 1fr;
  grid-gap: 5px;
  align-items: center;
  margin-top: 23px;
}

.similarGoods {
  margin-top: 40px;
}

.similarGoodsTitle {
  composes: fs-24 from '../../styles/common.module.scss';
  composes: lh-24 from '../../styles/common.module.scss';
  composes: colorBlue from '../../styles/common.module.scss';
  composes: ff-BN from '../../styles/common.module.scss';
}

.goodsList {
  composes: dg from '../../styles/common.module.scss';
  composes: ma-t20 from '../../styles/common.module.scss';
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 73px;
}

.description {
  composes: fs-14 from '../../styles/common.module.scss';
}

.pagination {
  margin: 30px 0 50px;
}

.slideWrapper {
  justify-content: center;
  display: inline-flex !important;
  img {
    width: 113px;
  }
}

.slickSlide {
  :global(.slick-slide) {
    margin-top: 10px;
  }
}

.arrow {
  position: absolute;
  top: 57px;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
  line-height: 15px;
  padding: 6px 11px;
}

.nextArrow {
  composes: arrow;
  right: -9px;
}

.prevArrow {
  composes: arrow;
  left: -9px;
}

@import 'AdvCardPage.large';
@import 'AdvCardPage.medium';
@import 'AdvCardPage.tablet';
@import 'AdvCardPage.mobile';
