@import '../../styles/respond_to';

@include respond-to(mobile) {
  .icon {
    height: 25px;
    width: 25px;
    margin-right: 0;
    top: 1px;
  }

  .heartIcon,
  .messageIcon {
    background-position: center;
    background-size: 90%;
  }

  .counterBlock {
    width: 12px;
    height: 12px;
    right: 0px;
    top: 0px;
  }
}
