@import "src/styles/colors.scss";
.btn {
  composes: bg-colorBlue from '../../styles/common.module.scss';
  composes: colorWhite from '../../styles/common.module.scss';
  composes: fs-18 from '../../styles/common.module.scss';
  composes: ff-BN from '../../styles/common.module.scss';
  composes: lh-18 from '../../styles/common.module.scss';
  composes: ta_c from '../../styles/common.module.scss';
  composes: cp from '../../styles/common.module.scss';
  composes: tt-U from '../../styles/common.module.scss';
  border: 2px solid $blue-color;
  border-radius: 10px;
  outline: none;
  width: 200px;
  height: 40px;

  &:hover {
    color: $blue-color;
    background-color: $white-color;
  }
}

.icon {
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: bottom;
  background-size: contain;
}

.cartIcon {
  background-image: url('../../images/icons/cart-white.png');
}

.cartIconHover {
  background-image: url('../../images/icons/cart-blue.png');
}

.messageIcon {
  background-image: url('../../images/icons/message-white.png');
}

.messageIconHover {
  background-image: url('../../images/icons/message-blue.png');
}

.heartIcon {
  background-image: url('../../images/icons/heart-white.png');
}

.heartIconHover {
  background-image: url('../../images/icons/heart-blue.png');
}

.heartActiveIcon {
  background-image: url('../../images/icons/heart-red.png');
}

.heartActiveIconHover {
  background-image: url('../../images/icons/heart-red-b.png');
}

@import 'Button.mobile';
