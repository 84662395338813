@import "src/styles/colors.scss";
.rules {
  composes: dg from '../../styles/common.module.scss';
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px 100px;
  margin: 20px 0 50px;
}

.text {
  composes: ff-GP from '../../styles/common.module.scss';
  composes: colorGray from '../../styles/common.module.scss';
}

.textGreen {
  composes: ff-GP from '../../styles/common.module.scss';
  composes: colorGreen from '../../styles/common.module.scss';
}

.title {
  composes: ma-t30 from '../../styles/common.module.scss';
}

.submit {
  composes: ma-t30 from '../../styles/common.module.scss';
}

.formSection {
  composes: rowWith2Cols from '../../styles/common.module.scss';
  composes: ma-b30 from '../../styles/common.module.scss';
}

.formInputHidden {
  border-bottom: none;
}

.link {
  composes: ff-GP from '../../styles/common.module.scss';
  composes: ta_c from '../../styles/common.module.scss';
  composes: colorBlue from '../../styles/common.module.scss';
  composes: fs-16 from '../../styles/common.module.scss';
  composes: ma-t20 from '../../styles/common.module.scss';
  composes: cp from '../../styles/common.module.scss';

  a {
    text-decoration: none;
    color: $blue-color;
  }
}

@import 'PwdResetPage.mobile';
