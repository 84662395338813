@import "src/styles/colors.scss";
@font-face {
  font-family: 'Gotham Pro';
  src: url('./fonts/GothamPro.ttf') format('opentype');
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('fonts/BebasNeue-Regular-Cyrillic.ttf') format('opentype');
}

.dg {
  display: grid;
}

.di {
  display: inline;
}

.gtc_5fr_14fr_5fr {
  grid-template-columns: 5fr 14fr 5fr;
}

.gtc_5fr_1100px_5fr {
  grid-template-columns: 5fr 1100px 5fr;
}

.tt-U {
  text-transform: uppercase;
}

.fw-B {
  font-weight: bold;
}

.fw-N {
  font-weight: normal;
}

.ff-BN {
  font-family: 'Bebas Neue', sans-serif;
}

.ff-GP {
  font-family: 'Gotham Pro', sans-serif;
}

.fs-8 {
  font-size: 8px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-48 {
  font-size: 48px;
}

.lh-11 {
  line-height: 11px;
}

.lh-13 {
  line-height: 13px;
}

.lh-16 {
  line-height: 16px;
}

.lh-18 {
  line-height: 18px;
}

.lh-20 {
  line-height: 20px;
}

.lh-22 {
  line-height: 22px;
}

.lh-24 {
  line-height: 24px;
}

.lh-38 {
  line-height: 38px;
}

.lh-100 {
  line-height: 100px;
}

.colorBlue {
  color: $blue-color;
}

.colorGray {
  color: $gray-color;
}

.colorLightGray {
  color: $light-gray-color;
}

.colorWhite {
  color: $white-color;
}

.colorRed {
  color: $red-color;
}

.colorDarkBlue {
  color: $dark-blue-color;
}

.colorGreen {
  color: $green-color;
}

.colorOrange {
  color: $orange-color;
}

.colorDarkYellow {
  color: $dark-yellow-color;
}

.bg-colorBlue {
  background-color: $blue-color;
}

.bg-colorGray {
  background-color: $gray-color;
}

.bg-colorWhite {
  background-color: $white-color;
}

.bg-colorRed {
  background-color: $red-color;
}

.bg-colorGreen {
  background-color: $green-color;
}

.bg-colorDarkYellow {
  background-color: $dark-yellow-color;
}

.cp {
  cursor: pointer;
}

.ta_c {
  text-align: center;
}

.ta_r {
  text-align: right;
}

.va_m {
  vertical-align: middle;
}

.tdl-U {
  text-decoration-line: underline;
}

.td_n {
  text-decoration: none;
}

.menuLinkTitle {
  composes: tt-U;
  composes: cp;
  color: $gray-color;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: $red-color;
  }
}

.ma-t5 {
  margin-top: 5px;
}

.ma-t10 {
  margin-top: 10px;
}

.ma-t20 {
  margin-top: 20px;
}

.ma-t30 {
  margin-top: 30px;
}

.ma-b30 {
  margin-bottom: 30px;
}

.ma-l30 {
  margin-left: 30px;
}

.formItem {
  composes: colorBlue;
  composes: ff-GP;
  composes: fs-18;
  composes: lh-11;

  padding: 5px 10px;
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;

  &::placeholder {
    color: $blue-color;
    opacity: 0.5;
  }
}

.rowWith {
  display: grid;
  grid-column-gap: 20px;
}

.rowWith2Cols {
  composes: rowWith;
  grid-template-columns: repeat(2, 1fr);
}

.rowWith3Cols {
  composes: rowWith;
  grid-template-columns: repeat(3, 1fr);
}

.rowWith4Cols {
  composes: rowWith;
  grid-template-columns: repeat(4, 1fr);
}

// by default all common styles designed for extralarge (>1600px)
@import 'common.large';
@import 'common.medium';
@import 'common.mobile';
