@import '../../styles/respond_to';

@include respond-to(medium) {
  .select {
    font-size: 12px;

    & span[role='option'] {
      font-size: 12px;
    }

    > div > input {
      font-size: 12px;

      &::placeholder {
        font-size: 12px;
      }
    }
  }
}
