@import '../../styles/respond_to';

@include respond-to(tablet) {
  .container {
    padding: 0;
    background: transparent;
    grid-template-areas:
      'title'
      'image'
      'content'
      'divider'
      'actions';
  }

  .content {
    max-width: 512px;
  }
}
