@import "src/styles/colors.scss";
.row {
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid $gray-color;
}

.input {
  composes: formItem from '../../styles/common.module.scss';
  font-size: 14px;
}

.btn {
  width: 20px;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position-x: 100%;
}

.check {
  background-image: url('../../images/icons/check-gray.png');

  &[data-correct='true'] {
    background-image: url('../../images/icons/check-green.png');
  }
}

.eye {
  background-image: url('../../images/icons/eye-gray.png');

  &[data-correct='true'] {
    background-image: url('../../images/icons/eye-blue.png');
  }
}

.cp {
  composes: cp from '../../styles/common.module.scss';
}

.suffix {
  composes: cp from '../../styles/common.module.scss';
  composes: colorBlue from '../../styles/common.module.scss';
  composes: tt-U from '../../styles/common.module.scss';
  composes: tdl-U from '../../styles/common.module.scss';
  composes: fs-18 from '../../styles/common.module.scss';
  composes: lh-18 from '../../styles/common.module.scss';
  composes: fw-N from '../../styles/common.module.scss';
  width: auto;
}

@import 'Input.mobile';
