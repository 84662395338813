@import "src/styles/colors.scss";
.container {
  position: fixed;
  z-index: 2;
}

.shadow {
  composes: cp from '../../styles/common.module.scss';
  background: rgba(255, 255, 255, 0.6);
  width: 100vw;
  height: 100vh;
}

.block {
  top: 50%;
  left: 50%;
  position: absolute;
  width: 500px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px #dddddd solid;
  transform: translate(-50%, -50%);
}

.close {
  composes: ta_r from '../../styles/common.module.scss';
}

.closeButton {
  composes: cp from '../../styles/common.module.scss';
  height: 10px;
  width: 10px;
  display: inline-block;
  vertical-align: middle;
  background: url('../../images/icons/close.png') no-repeat;
}

@import 'Modal.mobile';
