$blue-color: #3e97ff;
$dark-blue-color: #11529f;
$light-blue-color: #e6f1fe;
$gray-color: #676766;
$light-gray-color: rgba(103, 103, 102, 0.5);
$light-black: rgba(0, 0, 0, 0.2);
$white-color: #ffffff;
$red-color: #f68667;
$green-color: #92ba05;
$light-green-color: #93ba0552;
$orange-color: #ff9900;
$yellow-color: #ffcd00;
$dark-yellow-color: #f8c700;
