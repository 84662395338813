@import '../../styles/respond_to';

@include respond-to(mobile) {
  .imageWrapper {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .advPrice {
    font-size: 10px;
    padding: 5px;
  }

  .advImage {
    max-width: 120px;
    max-height: 180px;
  }

  .advTitle {
    font-size: 10px;
  }

  .icon {
    width: 15px;
    height: 15px;
    background-size: 15px;
  }
}
