@import '../../styles/respond_to';

@include respond-to(medium) {
  .headerContent {
    grid-template-areas: 'logo menu actionMenu';
  }

  .menu {
    grid-column-gap: 10px;
  }

  .logo {
    width: 80px;
    height: 46px;
  }
}

@include respond-to(tablet) {
  .header {
    grid-template-columns: auto;
  }

  .menu {
    grid-column-gap: 7px;
    justify-content: left;
  }

  .icon {
    height: 15px;
    width: 15px;
    background-size: 15px;
  }

  .userName {
    margin-top: 0;
    font-size: 15px;
  }

  .headerContent {
    height: 66px;
    padding: 0 0 0 5px;
    grid-gap: 0;
  }

  .actionMenu {
    cursor: pointer;
    justify-self: end;
  }
}
