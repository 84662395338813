@import '../../styles/respond_to';

@include respond-to(mobile) {
  .delAction {
    justify-content: space-around;
    margin-left: 10px;

    div {
      margin-top: 20px;
    }
  }
}
