@import "src/styles/colors.scss";
.footer {
  composes: bg-colorGray from '../../styles/common.module.scss';
  composes: colorWhite from '../../styles/common.module.scss';
}

.footerContent {
  grid-column: 2;
  padding: 15px 0 20px;
}

.banner {
  width: 100%;
}

.hzList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  li {
    float: left;
    padding: 0 10px;
  }
}

.footerWrapper {
  display: grid;
  grid-template-areas: 'logo logo . . . section section section section . . . social social';
  justify-items: center;
  align-items: center;
  padding: 10px 10px;
}

.logo {
  grid-area: logo;
}

.sections {
  grid-area: section;
}

.menuLink {
  composes: menuLinkTitle from '../../styles/common.module.scss';
  color: $white-color;
}

.selectedMenu {
  color: $red-color;
}

.disclaimer {
  composes: ta_c from '../../styles/common.module.scss';
  composes: fs-14 from '../../styles/common.module.scss';
  composes: lh-13 from '../../styles/common.module.scss';
  composes: ff-GP from '../../styles/common.module.scss';
  composes: ma-t30 from '../../styles/common.module.scss';
}

@import 'Footer.mobile';
