@import "src/styles/colors.scss";
.hzList {
  composes: tt-U from '../../styles/common.module.scss';
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  li {
    float: left;
    padding: 0 10px;
  }
}

.menuLink {
  composes: menuLinkTitle from '../../styles/common.module.scss';
}

.selectedMenu {
  color: $red-color;
}

.icon {
  vertical-align: middle;
  margin-right: 5px;
}

@import 'Menu.mobile';
