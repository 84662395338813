@import "src/styles/colors.scss";
.rules {
  composes: dg from '../../styles/common.module.scss';
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px 100px;
  margin: 20px 0 50px;
}

.text {
  composes: ff-GP from '../../styles/common.module.scss';
  composes: colorGray from '../../styles/common.module.scss';
}

.title {
  composes: ma-t30 from '../../styles/common.module.scss';
}

@import 'RulesPage.large';
@import 'RulesPage.medium';
@import 'RulesPage.mobile';
