@import '../../styles/respond_to';

@include respond-to(mobile) {
  .footer {
    font-size: 14px;
  }

  .footerWrapper {
    grid-template-areas:
      '. logo .'
      'section section section'
      'social social social';
    padding: 0 24px;
    grid-row-gap: 20px;
  }

  .sections {
    display: flex;
    width: 100%;
    justify-content: space-between;

    li {
      padding: 0;
    }
  }

  .disclaimer {
    font-size: 8px;
    margin-top: 25px;
  }
}
