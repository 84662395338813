@import '../../styles/respond_to';

@include respond-to(mobile) {
  .input {
    font-size: 10px;

    &::placeholder {
      font-size: 10px;
    }
  }

  .suffix {
    font-size: 12px;
  }
}
