@mixin respond-to($size) {
  @if $size == mobile {
    @media only screen and (max-width: 512px) {
      @content;
    }
  }

  @if $size == tablet {
    @media only screen and (max-width: 900px) {
      @content;
    }
  }

  @if $size == medium {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }

  @if $size == large {
    @media only screen and (max-width: 1600px) {
      @content;
    }
  }

  @if $size == extralarge {
    @media only screen and (min-width: 1601px) {
      @content;
    }
  }
}
