@import "src/styles/colors.scss";
.select {
  composes: formItem from '../../styles/common.module.scss';

  & span[role='listitem'] {
    background: transparent;
    color: $blue-color;

    & > :global(.react-dropdown-select-option-remove) {
      color: #f68667;
    }
  }

  & span[role='option'] {
    font-size: 14px;
  }

  > div > input {
    font-size: 14px;

    &::placeholder {
      color: $blue-color;
      opacity: 0.5;
    }
  }

  &:focus-within {
    box-shadow: none !important;
  }
}

@import 'Select.medium';
@import 'Select.mobile';
