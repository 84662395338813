@import "src/styles/colors.scss";
.row {
  composes: gtc_5fr_1100px_5fr from '../../styles/common.module.scss';
  composes: dg from '../../styles/common.module.scss';
  background-color: rgba(221, 184, 6, .2);
  padding: 20px 0;
  grid-column: 1/span 3;

  &:nth-child(2n - 1) {
    background-color: transparent;
  }
}
