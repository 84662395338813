@import '../../styles/respond_to';

@include respond-to(mobile) {
  .container {
    padding-bottom: 0;
  }

  .content {
    grid-template-columns: 1fr;
  }

  .col1 {
    width: calc(100% - 10px);
  }

  .imageSize {
    height: 192px;
    width: 315px;
    border-radius: 0;
    margin: 20px 0 0 -8px;
  }

  .imageYellow {
    background: url('../../images/banners/b-top-512.png') no-repeat;
  }

  .imageRed {
    background: url('../../images/banners/b-bottom-512.png') no-repeat;
  }

  .submitBlock {
    grid-gap: 40px;

    div,
    button {
      font-size: 12px;
    }
  }

  .publishBtn {
    width: 140px;
  }

  .formItem {
    font-size: 16px;
  }

  .h3 {
    font-size: 16px;
  }

  .photos {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 70px;
  }
}
