@import "src/styles/colors.scss";
.counter {
  composes: ff-GP from '../../styles/common.module.scss';
  height: 20px;
  width: 20px;
  font-size: 12px;
  border-radius: 100%;
  color: $gray-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yellow {
  background-color: $yellow-color;
}

.green {
  background-color: $green-color;
}

.gray {
  background-color: $light-green-color;
  color: #c4c4c4;
}

@import 'Counter.mobile';
