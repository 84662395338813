@import "src/styles/colors.scss";
.message {
  padding: 10px;
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.question {
  background-color: #ffffff;
  grid-column: 1 / span 4;
  border-radius: 0 10px 10px 10px;
  margin-left: 10px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 16px 16px 0;
    border-color: transparent #ffffff transparent transparent;
  }
}

.answer {
  background-color: $light-blue-color;
  grid-column: 3 / span 6;
  border-radius: 10px 0 10px 10px;
  margin-right: 10px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: -14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 16px 0 0;
    border-color: $light-blue-color transparent transparent transparent;
  }
}

.msgSenderName {
  composes: ta_l from '../../styles/common.module.scss';
  color: $light-gray-color;
  grid-column: 1 / span 2 ;
}

.msgDate {
  composes: ta_r from '../../styles/common.module.scss';
  composes: fs-12 from '../../styles/common.module.scss';
  color: $light-gray-color;
  grid-column: 3 / span 2;
  span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
}

.msgText {
  grid-column: 1 / span 3;
}

.msgIcon {
  // grid-column: 4 / span 4;
}