@import "src/styles/colors.scss";
.delAction {
  composes: ta_r from '../../styles/common.module.scss';
  grid-area: del;
  display: flex;
  grid-column-gap: 25px;
  justify-content: flex-end;
  flex-wrap: wrap;
}

@import 'DeleteActionBlock.mobile';
