@import "src/styles/colors.scss";
.content {
  grid-column: 2;
  padding: 0 10px;
}

.margin {
  margin: 30px 0 50px;
}

@import 'ContentBlock.large';
@import 'ContentBlock.mobile';
