@import '../../styles/respond_to';

@include respond-to(mobile) {
  .header {
    grid-template-columns: 10px auto;
  }

  .menu {
    grid-column-gap: 7px;
    justify-content: space-evenly;
    align-items: center;
  }

  .icon {
    height: 20px;
    width: 20px;
    background-size: 20px;
  }

  .userName {
    margin-top: 0;
    font-size: 15px;
  }

  .headerContent {
    height: 66px;
    padding: 0 0 0 5px;
    grid-gap: 0;
  }

  .actionMenu {
    cursor: pointer;
    justify-self: end;
  }
}
