@import "src/styles/colors.scss";
.header {
  composes: ta_c from '../../styles/common.module.scss';
  composes: ff-GP from '../../styles/common.module.scss';
}

.h1 {
  composes: colorBlue from '../../styles/common.module.scss';
  composes: fs-20 from '../../styles/common.module.scss';
  composes: lh-22 from '../../styles/common.module.scss';
}

.h2 {
  composes: ma-t5 from '../../styles/common.module.scss';
  composes: fs-14 from '../../styles/common.module.scss';
  composes: lh-16 from '../../styles/common.module.scss';
  composes: colorGray from '../../styles/common.module.scss';
}

.formItem {
  composes: fs-20 from '../../styles/common.module.scss';
  composes: lh-24 from '../../styles/common.module.scss';
}

.submit {
  composes: ma-t30 from '../../styles/common.module.scss';
  composes: ta_c from '../../styles/common.module.scss';
  margin-bottom: 50px;
}

.regBtn {
  composes: colorBlue from '../../styles/common.module.scss';
  composes: fs-16 from '../../styles/common.module.scss';
  composes: ma-t20 from '../../styles/common.module.scss';
  composes: cp from '../../styles/common.module.scss';
  composes: ff-GP from '../../styles/common.module.scss';
}

.error {
  composes: fs-16 from '../../styles/common.module.scss';
  composes: ff-GP from '../../styles/common.module.scss';
  composes: ma-t20 from '../../styles/common.module.scss';
  composes: colorRed from '../../styles/common.module.scss';
}

.link {
  composes: ff-GP from '../../styles/common.module.scss';
  composes: ta_c from '../../styles/common.module.scss';
  composes: colorBlue from '../../styles/common.module.scss';
  composes: fs-16 from '../../styles/common.module.scss';
  composes: ma-t20 from '../../styles/common.module.scss';
  composes: cp from '../../styles/common.module.scss';
  a {
    text-decoration: none;
    color: $blue-color;
  }
}

