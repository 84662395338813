@import '../../styles/respond_to';

@include respond-to(mobile) {
  .select {
    & > div > span:not([role='option']) {
      font-size: 14px;
    }

    > div > input {
      font-size: 14px !important;
    }

    > div > input:only-child {
      min-width: fit-content;
    }
  }

  .advs {
    grid-template-columns: repeat(2, 140px);
    grid-gap: 20px;
  }

  .newAdvertBtnMobile {
    grid-column: 2;
    margin-top: 10px;
    justify-self: center;
  }
}
