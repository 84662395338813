@import '../../styles/respond_to';

@include respond-to(medium) {
  .rules {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 20px;
  }

  .text {
    font-size: 14px;
  }
}
