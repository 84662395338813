@import '../../styles/respond_to';

@include respond-to(mobile) {
  .rules {
    grid-template-columns: 1fr;
  }

  .text {
    font-size: 12px;
  }
}
