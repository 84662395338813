@import '../../styles/respond_to';

@include respond-to(medium) {
  .container {
    padding: 7px;
  }

  .description {
    font-size: 10px;
  }

  .image {
    width: 349px;
  }

  .location {
    grid-template-columns: 25px 1fr;
    margin-top: 10px;
  }

  .divider {
    margin: 10px 0;
  }

  .sellerInfo {
    margin-top: 20px;
  }

  .label {
    font-size: 16px;
    line-height: 16px;
  }

  .value {
    font-size: 14px;
    line-height: 14px;
  }

  .priceBlock {
    padding: 25px 26px;
  }

  .goodsList {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 60px;
  }
}
