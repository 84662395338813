@import "src/styles/colors.scss";
.height100 {
  height: 100%;
}

.pageTitle {
  margin: 5px 0 0 0;
}

.tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.tab {
  composes: tt-U from '../../styles/common.module.scss';
  composes: cp from '../../styles/common.module.scss';
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-column-gap: 10px;
  padding: 15px 20px;
  color: #c4c4c4;
  background-color: #fdfbf2;
}

.productName {
}

.conversationSwitcher {
  display: none;
}

.activeTab {
  color: black;
  background-color: rgba(255, 205, 0, 0.2);
}

.containerHeight {
  height: calc(100% - 85px);
}

.container {
  composes: ma-b30 from '../../styles/common.module.scss';
  display: grid;
  grid-template-columns: 350px 1fr;
  background-color: rgba(255, 205, 0, 0.2);
  border: 2px solid $light-black;
  height: 100%;
  min-height: 200px;
}

.conversationsList {
  position: absolute;
  left: 0;
  top: 38px;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.conversation {
  composes: cp from '../../styles/common.module.scss';
  composes: ff-GP from '../../styles/common.module.scss';
  composes: fs-10 from '../../styles/common.module.scss';
  padding: 5px;
  border-bottom: 1px solid $gray-color;
  display: grid;
  grid-template-columns: 60px 2fr 1fr;
  background-color: #ffffff;
}

.conversationInProduct {
  composes: conversation;
  grid-template-columns: 2fr 1fr;
  padding-left: 20px;
}
.selected {
  background-color: #e5e5e5;
}

.advImage {
  width: 54px;
  height: 70px;
  border-radius: 10px;
}

.col2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.col3 {
  composes: flexCol;
  align-items: flex-end;
  text-align: center;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 5px;
  align-self: center;
}

.userInfoBlock {
  composes: flexCol;
  justify-content: center;
}

.maT20 {
  composes: ma-t20 from '../../styles/common.module.scss';
}

.grayColor {
  composes: colorGray from '../../styles/common.module.scss';
}

.lightGrayColor {
  composes: colorLightGray from '../../styles/common.module.scss';
}

.bold {
  composes: fw-B from '../../styles/common.module.scss';
}

.left {
  border-right: 2px solid $light-black;
  position: relative;
}

.right {
  composes: ff-GP from '../../styles/common.module.scss';
  position: relative;
}

.top {
  border-bottom: 1px solid $gray-color;
  display: grid;
  grid-template-columns: 3fr 1fr;
  background-color: #ffffff;
  padding: 21px 0 21px 25px;
  right: 0;
  left: 0;
}

.productName {
  composes: colorBlue from '../../styles/common.module.scss';
  composes: fs-20 from '../../styles/common.module.scss';
  padding-left: 10px;
  padding-bottom: 10px;
}

.productStatus {
  composes: fs-16 from '../../styles/common.module.scss';
  composes: colorGray from '../../styles/common.module.scss';
  display: flex;
  grid-gap: 15px;
}

.productStatusItem {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-column-gap: 10px;
}

.bottom {
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.textArea {
  composes: ff-GP from '../../styles/common.module.scss';
  composes: fs-14 from '../../styles/common.module.scss';

  padding: 31px;
  width: 100%;
  border: none;
  border-top: 1px solid $gray-color;
  height: 80px;
  box-sizing: border-box;
  outline: none;

  &::placeholder {
    padding-left: 5px;
    color: $light-gray-color;
  }
}

.saveBtn {
  composes: cp from '../../styles/common.module.scss';
  position: absolute;
  top: 27px;
  right: 16px;
  height: 25px;
  width: 25px;
}

.center {
  padding: 15px;
  display: grid;
  grid-row-gap: 20px;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: max-content;
  position: absolute;
  top: 86px;
  bottom: 80px;
  left: 0;
  right: 0;
  overflow: auto;
}

.searchBlock {
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 7px 5px;
  background-color: #fdf2c2;
  align-items: center;
  border-bottom: 1px solid $gray-color;
  position: absolute;
  left: 0;
  right: 0;
}

.inputWrapper {
  position: relative;
}

.removeSearchTextBtn {
  composes: cp from '../../styles/common.module.scss';
  position: absolute;
  right: 6px;
  bottom: 4px;
  width: 10px;
  height: 10px;
  background-size: contain;
  background-image: url('../../images/icons/close.png');
}

.searchIcon {
  height: 25px;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  background-image: url('../../images/icons/search.png');
}

.searchInput {
  background-color: #ffffff;
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  outline: none;
  padding: 0 20px 0 10px;
}

.searchBtn {
  composes: cp from '../../styles/common.module.scss';
  composes: ff-BN from '../../styles/common.module.scss';
  composes: fw-B from '../../styles/common.module.scss';
  composes: fs-16 from '../../styles/common.module.scss';
  composes: lh-16 from '../../styles/common.module.scss';
  composes: ta_c from '../../styles/common.module.scss';
  composes: colorGray from '../../styles/common.module.scss';
  padding-top: 5px;
}

.textBtnWrapper {
  margin: -10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.textBtn {
  line-height: 21px;
}

.rightBlock {
  display: flex;
  align-items: center;
}

.sold {
  cursor: default;
  margin-right: 5px;
}

@import 'MessengerPage.mobile';
