@import '../../styles/respond_to';

@include respond-to(mobile) {
  .btn {
    width: 140px;
    height: 35px;
    font-size: 12px;
    line-height: 12px;
  }

  .icon {
    width: 15px;
    height: 15px;
  }
}
