@import "src/styles/colors.scss";
.header {
  composes: bg-colorWhite from '../../styles/common.module.scss';
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: relative;
}

.headerContent {
  grid-column: 2;
  grid-row: 1;
  display: grid;
  grid-gap: 10px;
  // 14 total columns
  grid-template-areas: 'logo logo menu menu menu menu menu menu menu menu menu actionMenu actionMenu actionMenu';
  align-items: center;
  height: 100px;
  padding: 0 10px;
}

.logo {
  grid-area: logo;
  height: 75px;
}

.menu {
  grid-area: menu;
  display: flex;
  align-items: baseline;
  grid-column-gap: 15px;
  justify-content: center;
}

.actionMenu {
  grid-area: actionMenu;
  display: flex;
  grid-gap: 15px;
}

.userName {
  composes: ma-t5 from '../../styles/common.module.scss';
}

.icon {
  height: 25px;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
}

.menuLink {
  composes: menuLinkTitle from '../../styles/common.module.scss';
}

.login {
  composes: cp from '../../styles/common.module.scss';
  composes: icon;
  background-image: url('../../images/icons/login.png');
}

.search {
  composes: cp from '../../styles/common.module.scss';
  composes: icon;
  background-image: url('../../images/icons/search.png');
}

.searchLink {
  color: $gray-color;
  text-decoration: none;
}

.facebook {
  composes: cp from '../../styles/common.module.scss';
  height: 25px;
  width: 25px;
  display: block;
  background: url('../../images/icons/fb.png') no-repeat;
}

.instagram {
  composes: cp from '../../styles/common.module.scss';
  height: 25px;
  width: 25px;
  display: block;
  background: url('../../images/icons/instagram.png') no-repeat;
}

.commonMobile {
  position: absolute;
  top: 66px;
  left: 0;
  right: 0;
  padding: 20px 10px 10px;
}

.mobileSearch {
  composes: commonMobile;
  background-color: rgba(255, 255, 255, 0.9);
}

.mobileMenu {
  composes: commonMobile;

  background-color: $red-color;
  display: flex;
  grid-gap: 10px;
  flex-direction: column;
  align-items: center;

  a {
    color: $white-color;

    &:hover {
      color: $light-blue-color;
    }
  }
}

.mobileMenuButton {
  & > path,
  & > rect {
    pointer-events: none;
  }
}

.divider {
  border-top: 1px solid $white-color;
  border-radius: 5px;
  width: 100%;
}

.socialLink {
  text-align: center;
}

@import 'Header.medium';
@import 'Header.mobile';
