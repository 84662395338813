@import "src/styles/colors.scss";
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  composes: ff-BN from '../../../styles/common.module.scss';
  composes: dg from '../../../styles/common.module.scss';
  composes: gtc_5fr_1100px_5fr from '../../../styles/common.module.scss';
}

.content {
  composes: ff-BN from '../../../styles/common.module.scss';
  grid-column: 1/24;
  flex: 1;
}

.footer {
  composes: ff-BN from '../../../styles/common.module.scss';
  composes: dg from '../../../styles/common.module.scss';
  composes: gtc_5fr_1100px_5fr from '../../../styles/common.module.scss';
}
