@import 'respond_to';

@include respond-to(medium) {
  .gtc_5fr_1100px_5fr {
    grid-template-columns: 5fr 780px 5fr;
  }
}

@include respond-to(tablet) {
  .gtc_5fr_1100px_5fr {
    grid-template-columns: 1fr 512px 1fr;
  }
}
