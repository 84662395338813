@import "../../styles/respond_to";

@include respond-to(medium) {
  .content {
    grid-column-gap: 40px;
  }

  .ruleBlock {
    padding-bottom: 0;

    & > div {
      &:nth-child(2n) {
        margin-top: 0;
        top: 0;
        left: 0;
      }
    }
  }

  .imageSize {
    width: 300px;
    height: 126px;
    margin-top: 20px;
  }
}
